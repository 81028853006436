import React from 'react';
import Lottie from 'react-lottie-player';
import greenAnimation from '../assets/animations/green.json'; 
import redAnimation from '../assets/animations/red.json'; 
import yellowAnimation from '../assets/animations/yellow.json'; 

const AnimatedGauge = ({ result }) => {

  let animationData;
  if (result === '1') {
    animationData = greenAnimation;
  } else if (result === '2') {
    animationData = yellowAnimation;
  } else if (result === '3') {
    animationData = redAnimation;
  }

  return (
    <Lottie
      play
      loop={false}
      animationData={animationData}
      style={{ width: '100%', height: '140px', marginTop: '20px' }}
      delay={1000}
    />
  );
};

export default AnimatedGauge;