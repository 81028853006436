import { styled } from '@mui/material/styles';
import { Button, IconButton, Box, Paper } from '@mui/material';
import theme from '../themes/hapy';

export const Root = styled('div')(({ theme }) => ({
    textAlign: 'center',
}));

export const Disclaimer = styled('div')(({ theme }) => ({
    maxWidth: '80%',
}));

export const AppHeader = styled('header')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '8%',
    color: theme.palette.text.primary,
}));

export const AppCharacter = styled('div')(({ theme }) => ({
    display: 'block',
    height: '25%',
    color: theme.palette.text.primary,
}));

export const TextArea = styled('textarea')(({ theme }) => ({
    width: '90%',
    borderRadius: '8px',
    padding: '10px',
    height: '200px',
    fontSize: '18px',
}));

export const LoaderContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '50px',
    height: '100vh',
    width: '100%',
    color: theme.palette.custom.loaderText,
    fontSize: '1.5em',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1000,
}));

export const Loader = styled('div')({
    display: 'flex',
    alignItems: 'center',
});

export const QuestionText = styled('h2')({
    fontSize: '26px',
    lineHeight: '33.6px',
    textAlign: 'center',
    margin: '10px 0',
    fontWeight: 500,
});

export const ButtonGroup = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 0,
    gap: '16px',
    margin: '10px 0',
});

export const Main = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    padding: '0 !important',
    height: '100vh',
    width: '100%',
    maxWidth: '576px',
    margin: '0 auto',
    backgroundColor: theme.palette.background.default,
});

export const QuestionButton = styled(Button)(({ theme, className }) => ({
    backgroundColor: theme.palette.secondary.main,
    width: '100%',
    padding: '20px !important',
    border: '0 !important',
    borderRadius: '8px !important',
    cursor: 'pointer',
    color: theme.palette.text.primary,
    fontSize: '23px !important',
    fontWeight: 500,
    lineHeight: '23px !important',
    textAlign: 'center',
    transition: 'background-color 0.3s ease-in-out',
    '&:hover': {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.text.primary,
    },
    '&.selected': {
        backgroundColor: 'black !important',
        color: theme.palette.text.secondary,
        border: `2px solid ${theme.palette.primary.main}`,
    },
}));

export const ConfirmButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    color: theme.palette.custom.loaderText,
    margin: '20px 0 0 0 !important',
    width: '100%',
    padding: '20px !important',
    textTransform: 'uppercase !important',
    '&:hover': {
        backgroundColor: theme.palette.background.secondary,
        color: theme.palette.text.secondary,
    },
}));

export const ResetButton = styled(Button)(({ theme }) => ({
    backgroundColor: 'transparent',
    color: theme.palette.custom.alertColor,
    margin: '20px 0 0 0 !important',
    width: '100%',
    padding: '20px !important',
    textTransform: 'uppercase !important',
    border: '2px solid ' + theme.palette.custom.alertColor + ' !important',
    '&:hover': {
        backgroundColor: theme.palette.custom.alertColor,
        color: theme.palette.text.secondary,
    },
}));

export const ReturnButton = styled(IconButton)(({ theme }) => ({
    //position: 'absolute !important',
    top: '10px !important',
    left: '10px !important',
    //color: theme.palette.custom.loaderText,
}));

export const Questionnaire = styled(Box)(({ theme }) => ({
    /*: 'absolute',*/
    backgroundColor: theme.palette.custom.questionBg,
    borderRadius: '20px 20px 0 0',
    padding: '20px',
    height: '70%',
    width: '100%',
    bottom: 0,
    margin: 0,
    textAlign: 'center',
}));

export const ResultsPaper = styled(Box)(({ theme }) => ({
    /*: 'absolute',*/
    backgroundColor: theme.palette.custom.questionBg,
    borderRadius: '20px 20px 0 0',
    padding: '0 15px',
    height: '100%',
    width: '100%',
    bottom: 0,
    margin: 0,
    textAlign: 'center',
    marginTop: '15px',
}));

export const Text = styled('div')(({ theme }) => ({
    display: 'block',
    color: theme.palette.text.primary,
    fontSize: '18px'
}));

export const Conditions = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '16px',
    margin: '10px 0',
    maxHeight: '40vh',
    overflowY: 'auto',
    paddingRight: '16px',
});

export const ConditionCard = styled(Paper)(({ theme }) => ({
    borderRadius: '10px',
    padding: '10px 20px',
    textAlign: 'left',
    boxShadow: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    backgroundColor: '#F3F3F3',
}));

export const ConditionDetails = styled(Box)({
    display: 'flex',
    width: '100%',
});

export const RedFlag = styled(Box)({
    width: '50%',
    textTransform: 'uppercase',
});

export const Common = styled(Box)({
    width: '50%',
    //textAlign: 'right',
    textTransform: 'uppercase',
});

export const OkClass = styled('span')(({ theme }) => ({
    color: theme.palette.custom.okColor,
    fontWeight: '800',
}));

export const AlertClass = styled('span')(({ theme }) => ({
    color: theme.palette.custom.alertColor,
    fontWeight: '800',
}));