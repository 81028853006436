import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Typography } from '@mui/material';
import { ResultsPaper, ConfirmButton, AppCharacter, Text } from './useStyles';
import alertDisclaimer from '../assets/images/alert_disclaimer.svg';

const Disclaimer = ({ onNext }) => {
    const { t } = useTranslation();

    return (
        <Container
            maxWidth="sm"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '100vh',
                textAlign: 'center',
                backgroundColor: '#FFCC00',
                padding: '20px',
                boxSizing: 'border-box',
            }}
        >
            <img
                src={alertDisclaimer}
                alt="Warning"
                style={{ maxHeight: '200px', marginBottom: '20px' }}
            />
            <Typography variant="h5" gutterBottom>
                {t('disclaimer_title')}
            </Typography>
            <AppCharacter>
                <img
                    src='/drita_14.png'
                    alt="Isabel"
                />
            </AppCharacter>
            <ResultsPaper sx={{ height: '60%', paddingTop: '20px' }}>
                <Text>
                    {t('disclaimer_text')}
                </Text>
                <ConfirmButton onClick={onNext}>
                    {t('accept_disclaimer')}
                </ConfirmButton>
            </ResultsPaper>
        </Container>
    );
};

export default Disclaimer;