const requireTheme = require.context('.', false, /\.js$/);

const themes = {};
requireTheme.keys().forEach((fileName) => {
    if (fileName === './index.js') return;
    const themeName = fileName.replace('./', '').replace('.js', '');
    themes[themeName] = requireTheme(fileName).default;
});

export default themes;
