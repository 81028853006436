import { useState, useEffect } from 'react';
import axios from 'axios';
import countries from './countries';


const useGeoData = () => {
  const [geoData, setGeoData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchGeoData = async () => {
      try {
        const response = await axios.get('https://api.vidaysalud.com/geo/', {
          headers: {
            'Authorization': 'Bearer uVw5Cpt6FS4KNjWexR83ULfcy2HsnTJr'  
          }
        });
        const isoCode = response.data.iso_code;
        const matchingCountry = countries.countries.country.find(country => country.abbreviation === isoCode);
        setGeoData(matchingCountry);
        setIsLoading(false);
      } catch (error) {
        setError('Error fetching data: ' + error.message);
        setIsLoading(false);
      }
    };

    fetchGeoData();
  }, []);  // Empty dependency array ensures this effect runs only once on mount

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return { geoData, isLoading, error }; 
};

export default useGeoData;
