import React, { useState } from 'react';
import InitialResultDisplay from './InitialResultDisplay';
import Disclaimer from './Disclaimer';
import ConditionsDisplay from './ConditionsDisplay';

const ResponseResults = ({ apiResponses, triageData, pleaseNote, diagnosesData, diagnosesDetails, onRestart }) => {
    const [showDisclaimer, setShowDisclaimer] = useState(false);
    const [showConditions, setShowConditions] = useState(false);

    // Create a structured JSON object similar to what was done in ConditionsDisplay
    const jsonDiag = {
        diagnosis_date: new Date().toLocaleString(),
        age_group: apiResponses.diagnoses_checklist.query_result_details.age_group,
        gender: apiResponses.diagnoses_checklist.query_result_details.gender,
        pregnancy: apiResponses.diagnoses_checklist.query_result_details.gender !== 'Hombre' ? apiResponses.diagnoses_checklist.query_result_details.pregnancy : null,
        region: apiResponses.diagnoses_checklist.query_result_details.region,
        symptoms: apiResponses.diagnoses_checklist.query_result_details.query_entered,
        diagnoses: apiResponses.diagnoses_checklist.diagnoses.map(diagnosis => ({
            name: diagnosis.diagnosis_name,
            specialty: diagnosis.specialty,
            ids: {
                snomed: diagnosis.snomed_diagnosis_id,
                icd9: diagnosis.icd9_diagnosis_id,
                icd10: diagnosis.icd10_diagnosis_id,
            },
            redFlag: diagnosis.red_flag === "true",
            commonDiagnosis: diagnosis.common_diagnosis === "true"
        }))
    };

    const handleNextToDisclaimer = () => {
        setShowDisclaimer(true);
    };

    const handleNextToConditions = () => {
        setShowConditions(true);
    };

    const handleBackToInitial = () => {
        setShowConditions(false);
        setShowDisclaimer(false);
    };

    if (showConditions) {
        return (
            <ConditionsDisplay
                apiResponses={apiResponses}
                jsonDiag={jsonDiag}
                onRestart={onRestart}
                onBack={handleBackToInitial}
            />
        );
    }

    if (showDisclaimer) {
        return (
            <Disclaimer onNext={handleNextToConditions} />
        );
    }

    return (
        <InitialResultDisplay
            apiResponses={apiResponses}
            jsonDiag={jsonDiag}
            triageData={triageData}
            onNext={handleNextToDisclaimer}
            onRestart={onRestart}
        />
    );
};

export default ResponseResults;
