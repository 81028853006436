import i18n from 'i18next';

const getTriageOutcome = (score) => {
    if (score >= 85) {
        return { title: `${i18n.t('emergency_services')}` , color: "red", score: score , text: `${i18n.t('emergency_services_text')}`, result:"3"};
    } else if (score >= 40) {
        return { title: `${i18n.t('urgent_care_clinic')}`, color: "#FFC810", score: score , text: `${i18n.t('urgent_care_clinic_text')}`, result:"2"};
    } else {
        return { title: `${i18n.t('telemedicine')}`, color: "#92C833", score: score , text: `${i18n.t('telemedicine_text')}`, result:"1"};
    }
};

export default getTriageOutcome;