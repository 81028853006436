import React from 'react';
import { ReturnButton } from './useStyles';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';

const BackButton = ({ show, goBack, t, color = 'black' }) => {
    if (!show) return null;
    return (
        <ReturnButton
            aria-label={t("back")}
            onClick={goBack}
            style={{ color: color }}>
            <ArrowBackIosRoundedIcon fontSize="large" />
        </ReturnButton>
    );
};

export default BackButton;