const ageGroupsDataEs = {
    "age_groups": {
        "age_group": [
            {
                "agegroup_id": "1",
                "ordinal": "0",
                "name": "Neonato",
                "yr_from": "0",
                "yr_to": "28d",
                "branch": "paediatric",
                "can_conceive": "false"
            },
            {
                "agegroup_id": "2",
                "ordinal": "2",
                "name": "Infantil",
                "yr_from": "29d",
                "yr_to": "1 año",
                "branch": "paediatric",
                "can_conceive": "false"
            },
            {
                "agegroup_id": "3",
                "ordinal": "4",
                "name": "Niño pequeño",
                "yr_from": "1",
                "yr_to": "5 años",
                "branch": "paediatric",
                "can_conceive": "false"
            },
            {
                "agegroup_id": "10",
                "ordinal": "5",
                "name": "Niño grande",
                "yr_from": "6",
                "yr_to": "12 años",
                "branch": "paediatric",
                "can_conceive": "false"
            },
            {
                "agegroup_id": "4",
                "ordinal": "6",
                "name": "Adolescente",
                "yr_from": "13",
                "yr_to": "16 años",
                "branch": "paediatric",
                "can_conceive": "true"
            },
            {
                "agegroup_id": "7",
                "ordinal": "8",
                "name": "Joven-adulto",
                "yr_from": "17",
                "yr_to": "29 años",
                "branch": "adult",
                "can_conceive": "true"
            },
            {
                "agegroup_id": "5",
                "ordinal": "10",
                "name": "Adulto",
                "yr_from": "30",
                "yr_to": "39 años",
                "branch": "adult",
                "can_conceive": "true"
            },
            {
                "agegroup_id": "8",
                "ordinal": "12",
                "name": "Adulto",
                "yr_from": "40",
                "yr_to": "49 años",
                "branch": "adult",
                "can_conceive": "true"
            },
            {
                "agegroup_id": "9",
                "ordinal": "14",
                "name": "Adulto",
                "yr_from": "50",
                "yr_to": "64 años",
                "branch": "adult",
                "can_conceive": "true"
            },
            {
                "agegroup_id": "6",
                "ordinal": "16",
                "name": "Geriátrica",
                "yr_from": "",
                "yr_to": "más de 65 años",
                "branch": "adult",
                "can_conceive": "false"
            }
        ]
    }
};
export default ageGroupsDataEs;