import React, { useState } from "react";
import PinModal from './pinModal';

const CallToAction = ({ jsonDiag }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleChat = () => {
        // Handle showing the modal and passing the structured API response to PinModal
        setIsModalOpen(true);
    };

    return (
        <div style={{ marginTop: "20px" }}>
            <button
                onClick={(e) => {
                    e.preventDefault();
                    handleChat(); // Open the modal on click
                }}
                style={{border: 'none', background: 'none', width: '100%'}}
            >
                <img
                    src="/cta.svg"
                    alt="GRATIS: Chatea ahora con un Doctor ahora y comparte estos resultados"
                    width="100%"
                />
            </button>

            {/* Pass the apiResponses to PinModal */}
            <PinModal
                open={isModalOpen}
                handleClose={() => setIsModalOpen(false)}
                jsonDiag={jsonDiag} // Pass the structured API response
            />
        </div>
    );
}

export default CallToAction;
