import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(HttpBackend) // loads translations from your server
  .use(LanguageDetector) // detect user language
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    lng: process.env.REACT_APP_LANGUAGE,
    //fallbackLng: 'en', // use en if detected lng is not available
    debug: false, // enable console output for debugging
    //detection: {
    //  order: ['queryString', 'cookie', 'localStorage', 'navigator', 'htmlTag'],
    //  caches: ['localStorage', 'cookie'],
    //},
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json', // path to the translation files
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
