import React from 'react';
import { QuestionText, Questionnaire, ButtonGroup, QuestionButton, ConfirmButton } from './useStyles';

const QuestionsDisplay = ({ question, onRespond, renderInput, isLast, isValid, onSubmit, isLoading, t, isInitial }) => {
    if (!question) return null; // Return null if question is undefined

    const handleResponse = (value) => {
        onRespond(value);
    };

    return (
        <Questionnaire>
            <QuestionText>{question.questionText}</QuestionText>
            {isInitial ? (
                <ButtonGroup>
                    {question.answers.map((answer, index) => (
                        <QuestionButton key={index} onClick={() => handleResponse(answer.value)}>
                            {answer.text}
                        </QuestionButton>
                    ))}
                </ButtonGroup>
            ) : (
                <>
                    {renderInput(question)}
                    {isLast && isValid && (
                        <ConfirmButton onClick={onSubmit} variant="contained" disabled={!isValid}>
                            {isLoading ? t("submitting") : t("submit")}
                        </ConfirmButton>
                    )}
                </>
            )}
        </Questionnaire>
    );
};

export default QuestionsDisplay;