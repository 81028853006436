const validateInput = (input, type) => {
    // If the input is not a string, it must be a number (from the radio buttons), which is always valid
    if (typeof input !== 'string') {
        return true;
    }

    // If the input is a string, then trim it and perform the necessary validation
    const trimmedInput = input.trim();
    let isValid = trimmedInput !== '';

    return isValid;
};

export default validateInput;