import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Container, Typography, Link } from '@mui/material';
import { Disclaimer } from './useStyles';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

const WelcomeScreen = ({ onContinue }) => {
    const { t } = useTranslation();

    return (
        <Container
            maxWidth="sm"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
                minHeight: '100vh',
                textAlign: 'center',
                backgroundColor: '#007991',
                color: '#fff',
                padding: '20px',
                boxSizing: 'border-box',
            }}
        >
            <img
                src='/logo_welcome.svg'
                alt="Vida y Salud Logo"
                style={{ maxWidth: '100%', marginTop: '50px' }}
            />
            <Typography variant="h5" style={{ whiteSpace: 'pre-line' }}>
                {t('welcome_message')}
            </Typography>
            <Button
                variant="contained"
                onClick={onContinue}
                sx={{ width: '80%', backgroundColor: '#fff', color: '#000', borderRadius: '50px', padding: '20px 30px', textTransform: 'uppercase', fontWeight: 'bold', fontSize: '1.3rem', letterSpacing: '2px' }}>
                <ArrowCircleRightIcon sx={{ marginRight: '5px' }}></ArrowCircleRightIcon>{t('continue')}
            </Button>
            <Disclaimer>
                {t('welcome_disclaimer')}
            </Disclaimer>
            <Disclaimer>
                {t('welcome_tyc')}
                <Link
                    href={t('welcome_terms_link')}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ color: '#000', textDecoration: 'underline', marginLeft: '5px' }}
                >
                    {t('welcome_terms')}
                </Link>
                {t('welcome_tyc_and')}
                <Link
                    href={t('welcome_privacy_link')}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ color: '#000', textDecoration: 'underline', marginLeft: '5px' }}
                >
                    {t('welcome_privacy')}
                </Link>

            </Disclaimer>

        </Container>
    );
};

export default WelcomeScreen;