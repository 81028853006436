import React, { useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import selectTheme from './components/selectTheme';
import CssBaseline from '@mui/material/CssBaseline';
import WelcomeScreen from './components/WelcomeScreen';
import Questionnaire from './components/Questionnaire';

const client = process.env.REACT_APP_CLIENT;
const theme = selectTheme(client);

function App() {
  const [showQuestionnaire, setShowQuestionnaire] = useState(false);

  const handleContinue = () => {
    setShowQuestionnaire(true);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        {showQuestionnaire ? <Questionnaire /> : <WelcomeScreen onContinue={handleContinue} />}
      </div>
    </ThemeProvider>
  );
}

export default App;