import { useEffect, useState } from 'react';

const usePredictiveText = () => {
    const [predictiveText, setPredictiveText] = useState([]);

    useEffect(() => {
        const fetchPredictiveText = async () => {
            try {
                const response = await fetch('/locales/es/autocomplete.json');
                const data = await response.json();
                setPredictiveText(data.predictive_text);
            } catch (error) {
                console.error('Error fetching predictive text:', error);
            }
        };

        fetchPredictiveText();
    }, []);

    return predictiveText;
};

export default usePredictiveText;