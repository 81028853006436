import React from 'react';
import { useTranslation } from 'react-i18next';
import he from 'he';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, DialogContentText, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const DiagnosisOverlay = ({ open, onClose, diagnosisName }) => {
    const { t } = useTranslation();

    const vysLink = `https://www.vidaysalud.com/?s=${encodeURIComponent(he.decode(diagnosisName))}`;
    const medlineLink = `https://vsearch.nlm.nih.gov/vivisimo/cgi-bin/query-meta?v%3Aproject=medlineplus-spanish&v%3Asources=medlineplus-spanish-bundle&query=${encodeURIComponent(he.decode(diagnosisName))}`;

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <DialogActions>
                <IconButton onClick={onClose} color="primary">
                    <CloseIcon />
                </IconButton>
            </DialogActions>


            <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '400px' }}>
                <img
                    src={'/drita_crcl.png'}
                    alt="Isabel"
                    style={{ maxHeight: '100px', width: '100px', margin: '10px auto' }}
                />
                <DialogTitle sx={{ textAlign: 'center', color: '#2F292B', fontFamily: 'Montserrat', fontSize: '24px', fontStyle: 'normal', fontWeight: '700', lineHeight: '120%', paddingBottom: '0px' }}>{t("learn_more")}</DialogTitle>
                <DialogContentText sx={{ color: '#2F292B', fontFamily: 'Montserrat', fontSize: '18px', fontStyle: 'normal', fontWeight: '700', lineHeight: '120%', textAlign: 'center' }}>{he.decode(diagnosisName)}</DialogContentText>
                <Button
                    variant="contained"
                    sx={{ width: '100%', margin: '10px auto', padding: '10px', backgroundColor: '#B69115' }}
                    onClick={() => {
                        window.open(medlineLink, '_blank');
                        onClose();
                    }}
                >
                    {t("learn_more_mediline")}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    sx={{ width: '100%', margin: '10px auto', padding: '10px' }}
                    onClick={() => {
                        window.open(vysLink, '_blank');
                        onClose();
                    }}
                >
                    {t("learn_more_vys")}
                </Button>
            </DialogContent>
        </Dialog>
    );
};

export default DiagnosisOverlay;
