import React from 'react';
import { AppCharacter, Questionnaire, QuestionText, Text, ConfirmButton } from './useStyles';
import { useTranslation } from 'react-i18next';

const ResponseNoResults = ({ apiResponses, onRestart }) => {
    const { t } = useTranslation();
    return (
        <>
            <AppCharacter>
                <img
                    src='/drita_9.png'
                    alt="Isabel"
                    style={{ maxHeight: '255px' }}
                />
            </AppCharacter>
            <Questionnaire>
                <QuestionText>{t('no_result')}</QuestionText>
                <Text>{apiResponses.diagnoses_checklist.no_result.information}</Text>
                <ConfirmButton variant="contained" onClick={onRestart}>
                    {t('try_again')}
                </ConfirmButton>
            </Questionnaire>
        </>
    );
};

export default ResponseNoResults;