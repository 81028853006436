import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import he from 'he';
import { ResultsPaper, QuestionText, Conditions, ConditionCard, ConditionDetails, RedFlag, Common, OkClass, AlertClass } from './useStyles';
import DiagnosisOverlay from './DiagnosisOverlay';
import CallToAction from './callToAction';
import ShareDiagnosis from './ShareDiagnosis';
import BackButton from './BackButton';

const ConditionsDisplay = ({ apiResponses, jsonDiag, onBack }) => {
    const { t } = useTranslation();
    const [overlayOpen, setOverlayOpen] = useState(false);
    const [selectedDiagnosis, setSelectedDiagnosis] = useState(''); // State for selected diagnosis
    const [pin,setPin] = useState(() => sessionStorage.getItem('userPin') || null);


    const handleOverlayOpen = (diagnosisName) => {
        setSelectedDiagnosis(diagnosisName);
        setOverlayOpen(true);
    };

    const handleOverlayClose = () => {
        setOverlayOpen(false);
    };

    const url = process.env.REACT_APP_URL;
    const subject = t("share_subject");

    return (
        <>
            <ResultsPaper>
                <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                    <BackButton show={true} goBack={onBack} t={t} />
                    <QuestionText style={{paddingTop: '20px'}}>{t("triage_results")}</QuestionText>
                    <div style={{ minWidth: '50px' }} />
                </div>
                <Conditions>
                    {apiResponses.diagnoses_checklist.diagnoses.map((diagnosis, index) => (
                        <ConditionCard key={index}>
                            <div className='diagnosis-text'>
                                <div className="condition"><strong>{he.decode(diagnosis.diagnosis_name)}</strong></div>
                                <ConditionDetails>
                                    {diagnosis.red_flag === "true" && (
                                        <RedFlag>
                                            <AlertClass>
                                                {t("red_flag")}
                                                <Tooltip title={t("red_flag_explanation")}>
                                                    <InfoIcon style={{ marginLeft: '5px', cursor: 'pointer', verticalAlign: 'text-bottom' }} />
                                                </Tooltip>
                                            </AlertClass>
                                        </RedFlag>
                                    )}
                                    {diagnosis.common_diagnosis === "true" && (
                                        <Common>
                                            <OkClass>
                                                {t("common_diagnosis")}
                                                <Tooltip title={t("common_diagnosis_explanation")}>
                                                    <InfoIcon style={{ marginLeft: '5px', cursor: 'pointer', verticalAlign: 'text-bottom' }} />
                                                </Tooltip>
                                            </OkClass>
                                        </Common>
                                    )}
                                </ConditionDetails>
                            </div>
                            <div className='diagnosis-icon'>
                                <img
                                    src='/links.svg'
                                    alt='Conoce más de esta condición'
                                    onClick={() => handleOverlayOpen(diagnosis.diagnosis_name)}
                                    style={{ cursor: 'pointer', width: '50px', height: '50px' }}
                                />
                            </div>
                        </ConditionCard>
                    ))}
                </Conditions>
                <ShareDiagnosis jsonDiag={jsonDiag} url={url} subject={subject} />
                <DiagnosisOverlay
                    open={overlayOpen}
                    onClose={handleOverlayClose}
                    diagnosisName={selectedDiagnosis}
                />
            <CallToAction pin={pin} setPin={setPin} jsonDiag={jsonDiag} />
            </ResultsPaper>
        </>
    );
};

export default ConditionsDisplay;
