import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2A98B3',
    },
    secondary: {
      main: '#C6C6C6',
    },
    background: {
      default: '#2A98B3',
      secondary: '#007991',
    },
    text: {
      primary: '#333',
      secondary: '#FFF',
    },
    custom: {
      loaderBg: '#B6BF0D',
      loaderText: '#FFF',
      questionBg: '#FFF',
      conditionBg: '#F5F5F5',
      okColor: '#27AE60',
      alertColor: '#EB5757',
      alertColorRoll: '#C74646',
    },
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',
    button: {
      fontSize: '23px',
      fontWeight: 500,
      lineHeight: '23px',
      textAlign: 'center',
      textTransform: 'none', // Keep original text transform
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          '&.question-button': {
            width: '90%',
            padding: '20px',
            border: '0',
            backgroundColor: '#E6E6E6',
            color: '#20292D',
          },
          '&.confirm, &.submit, &.restart': {
            backgroundColor: '#2A98B3',
            color: '#FFF',
            margin: '20px 0 0 0',
          },
          '&.back-button': {
            position: 'absolute',
            top: '10px',
            left: '10px',
            color: '#FFF',
          },
        },
      },
    },
  },
});

export default theme;
