import React, { useState, useEffect, useRef } from 'react';
import { RiWhatsappFill, RiTelegramFill, RiMailFill, RiChatHeartFill } from 'react-icons/ri';
import { ConfirmButton } from './useStyles';
import PinModal from './pinModal';
import { useTranslation } from 'react-i18next';
import '../css/ShareDiagnosis.css';

const ShareDiagnosis = ({ jsonDiag, subject, url }) => {
  const { t } = useTranslation();
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const popupRef = useRef(null);

  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsPopupVisible(false);
    }
  };

  useEffect(() => {
    if (isPopupVisible) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isPopupVisible]);

  // Helper function to format the JSON object into a human-readable string
  const formatJsonDiagMessage = (jsonDiag) => {
    let message = '';

    if (jsonDiag.diagnosis_date) {
      message += `${t('diagnosis_date')}: ${jsonDiag.diagnosis_date}\n\n`;
    }

    if (jsonDiag.age_group) {
      message += `${t('age_group')}: ${jsonDiag.age_group}\n\n`;
    }

    if (jsonDiag.gender) {
      message += `${t('gender')}: ${jsonDiag.gender}\n\n`;
    }

    // Only add the pregnancy field if the gender is not 'hombre'
    if (jsonDiag.pregnancy && jsonDiag.gender !== 'hombre') {
      message += `${t('pregnancy')}: ${jsonDiag.pregnancy}\n\n`;
    }

    if (jsonDiag.region) {
      message += `${t('region')}: ${jsonDiag.region}\n\n`;
    }

    if (jsonDiag.symptoms) {
      message += `${t('symptoms')}: ${jsonDiag.symptoms}\n\n\n`;
    }

    if (jsonDiag.diagnoses && Array.isArray(jsonDiag.diagnoses)) {
      message += `${t('diagnosis_results').toUpperCase()}:\n`;
      jsonDiag.diagnoses.forEach((diagnosis, index) => {
        message += `\n\t${index + 1}. ${t('diagnosis_name')}: ${diagnosis.name}\n`;
        message += `\t${t('specialty')}: ${diagnosis.specialty}\n`;
        if (diagnosis.redFlag) {
          message += `\t${t('red_flag').toUpperCase()}\n`;
        }
        if (diagnosis.commonDiagnosis) {
          message += `\t${t('common_diagnosis').toUpperCase()}\n`;
        }
        if (diagnosis.ids) {
          if (diagnosis.ids.snomed) {
            message += `\tSNOMED ID: ${diagnosis.ids.snomed}\n`;
          }
          if (diagnosis.ids.icd9) {
            message += `\tICD-9 ID: ${diagnosis.ids.icd9}\n`;
          }
          if (diagnosis.ids.icd10) {
            message += `\tICD-10 ID: ${diagnosis.ids.icd10}\n`;
          }
        }
      });
    }

    return message;
  };

  const formattedMessage = formatJsonDiagMessage(jsonDiag); // Generate the formatted message

  const shareToWhatsApp = () => {
    const whatsappMessage = `${formattedMessage}\n${t('link_app')}: ${url}`;
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(whatsappMessage)}`;
    window.open(whatsappUrl, '_blank');
    setIsPopupVisible(false);
  };

  const shareToTelegram = () => {
    const telegramMessage = `${formattedMessage}\n${t('link_app')}: ${url}`;
    const telegramUrl = `https://t.me/share/url?url=${encodeURIComponent(url)}&text=${encodeURIComponent(telegramMessage)}`;
    window.open(telegramUrl, '_blank');
    setIsPopupVisible(false);
  };

  const shareToEmail = () => {
    const emailMessage = `${formattedMessage}\n${t('link_app')}: ${url}`;
    const emailUrl = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(emailMessage)}`;
    window.open(emailUrl, '_blank');
    setIsPopupVisible(false);
  };

  const isPinEnabled = process.env.REACT_APP_SHARE_PIN_ENABLED === 'true';

  const shareToChat = () => {
    setIsModalOpen(true);
    setIsPopupVisible(false);
  };

  return (
    <>
      <div className="share-diagnosis" ref={popupRef}>
        <ConfirmButton variant="contained" onClick={togglePopup}>
          {t('share_results')}
        </ConfirmButton>
        {isPopupVisible && (
          <div className="share-popup">
            <button onClick={shareToWhatsApp} className="share-button whatsapp">
              <RiWhatsappFill /> WhatsApp
            </button>
            <button onClick={shareToTelegram} className="share-button telegram">
              <RiTelegramFill /> Telegram
            </button>
            <button onClick={shareToEmail} className="share-button email">
              <RiMailFill /> Email
            </button>
            {isPinEnabled && (
              <button onClick={shareToChat} className="share-button chat">
                <RiChatHeartFill /> Chat Médico
              </button>
            )}
          </div>
        )}
      </div>

      {/* Pass email details to PinModal */}
      <PinModal
        open={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        jsonDiag={jsonDiag}
      />
    </>
  );
};

export default ShareDiagnosis;
