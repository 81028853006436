import { useTranslation } from 'react-i18next';
import { getTranslationPrefix, getDynamicTranslation } from "./translationUtils";

const useQuestions = (forSelf = true) => {
    const { t } = useTranslation();

    const selfOrOtherPrefix = getTranslationPrefix(t, forSelf);

    const questions = [
        {
            id: 'forWhom',
            questionText: t("for_whom_question"),
            inputType: 'button',
            answers: [
                { text: t("for_myself"), value: 'self' },
                { text: t("for_someone_else"), value: 'other' }
            ],
            pic: 'drita_1.png',
            backgroundColor: '#2A98B3'
        },
        {
            id: 'dobGroup',
            questionText: getDynamicTranslation(t, selfOrOtherPrefix, 'dob_title'),
            inputType: 'button',
            answers: [
                { text: t('kids'), value: 'kids' },
                { text: t('young_adult'), value: 'young_adult' },
                { text: t('elder'), value: 'elder' },
            ],
            pic: 'drita_2.png',
            backgroundColor: '#2A98B3',
        },
        {
            id: 'dob',
            questionText: getDynamicTranslation(t, selfOrOtherPrefix, 'dob_title'),
            inputType: 'button',
            answers: [], // Answers will be populated dynamically based on the selection in 'dobGroup'
            pic: 'drita_3.png',
            backgroundColor: '#2A98B3',
        },
        {
            id: 'sex',
            questionText: getDynamicTranslation(t, selfOrOtherPrefix, "sex_title"),
            inputType: 'button',
            answers: [
                { text: t("male"), value: 'm' },
                { text: t("female"), value: 'f' },
            ],
            pic: 'drita_4.png',
            backgroundColor: '#2A98B3'
        },
        {
            id: 'pregnant',
            questionText: getDynamicTranslation(t, selfOrOtherPrefix, "pregnant_title"),
            inputType: 'button',
            answers: [
                { value: 'y', text: t("yes") },
                { value: 'n', text: t("no") },
                { value: '0', text: t("not_sure") },
            ],
            pic: 'drita_5.png',
            backgroundColor: '#2A98B3'
        },
        {
            id: 'querytext',
            questionText: getDynamicTranslation(t, selfOrOtherPrefix, "symptoms_title"),
            inputType: 'text',
            pic: 'drita_6.png',
            backgroundColor: '#2A98B3'
        },
        {
            id: 'Q1',
            questionText: getDynamicTranslation(t, selfOrOtherPrefix, "q1_title"),
            answers: [
                { text: t("over_minutes"), value: 1 },
                { text: t("over_days"), value: 2 },
                { text: t("over_weeks"), value: 3 },
                { text: t("over_months"), value: 4 },
            ],
            inputType: 'button',
            pic: 'drita_7.png',
            backgroundColor: '#2A98B3'
        },
        {
            id: 'Q2',
            questionText: getDynamicTranslation(t, selfOrOtherPrefix, "q2_title"),
            answers: [
                { text: t("0-6_days"), value: 1 },
                { text: t("weeks"), value: 2 },
                { text: t("months"), value: 3 },
                { text: t("years"), value: 4 },
            ],
            inputType: 'button',
            pic: 'drita_8.png',
            backgroundColor: '#2A98B3'
        },
        {
            id: 'Q3',
            questionText: getDynamicTranslation(t, selfOrOtherPrefix, "q3_title"),
            answers: [
                { text: t("better"), value: 1 },
                { text: t("same"), value: 2 },
                { text: t("worse"), value: 3 },
            ],
            inputType: 'button',
            pic: 'drita_9.png',
            backgroundColor: '#2A98B3'
        },
        {
            id: 'Q4',
            questionText: getDynamicTranslation(t, selfOrOtherPrefix, "q4_title"),
            answers: [
                { text: t("none"), value: 1 },
                { text: t("mild_discomfort"), value: 2 },
                { text: t("very_uncomfortable"), value: 3 },
                { text: t("unbearable"), value: 4 },
            ],
            inputType: 'button',
            pic: 'drita_10.png',
            backgroundColor: '#2A98B3'
        },
        {
            id: 'Q5',
            questionText: getDynamicTranslation(t, selfOrOtherPrefix, "q5_title"),
            answers: [
                { text: getDynamicTranslation(t, selfOrOtherPrefix, "no_effect"), value: 1 },
                { text: getDynamicTranslation(t, selfOrOtherPrefix, "struggling"), value: 2 },
                { text: getDynamicTranslation(t, selfOrOtherPrefix, "unable"), value: 3 },
            ],
            inputType: 'button',
            pic: 'drita_12.png',
            backgroundColor: '#2A98B3'
        },
        {
            id: 'Q6',
            questionText: getDynamicTranslation(t, selfOrOtherPrefix, "q6_title"),
            answers: [
                { text: getDynamicTranslation(t, selfOrOtherPrefix, "not_taking_any"), value: 1 },
                { text: t("yes"), value: 2 },
                { text: t("no"), value: 3 },
            ],
            inputType: 'button',
            pic: 'drita_11.png',
            backgroundColor: '#2A98B3'
        },
        {
            id: 'Q7',
            questionText: getDynamicTranslation(t, selfOrOtherPrefix, "q7_title"),
            answers: [
                { text: t("no"), value: 1 },
                { text: t("yes"), value: 2 },
            ],
            inputType: 'button',
            pic: 'drita_6.png',
            backgroundColor: '#2A98B3'
        }
    ];

    return questions;
};

export default useQuestions;