import themes from '../themes';

const selectTheme = (client) => {
   /* if (!client) {
        return themes.default;
    }*/

    return themes[client] || themes.default;
};

export default selectTheme;
