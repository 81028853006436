import { useState, React } from 'react'; 
import { useTranslation } from 'react-i18next';
import { QuestionText, ResultsPaper, Text, ConfirmButton } from './useStyles';
import AnimatedGauge from './AnimatedGauge';
import getTriageOutcome from './getTriageOutcome';
import CallToAction from './callToAction';

const InitialResultDisplay = ({ apiResponses, triageData, jsonDiag, onNext }) => {
    const { t } = useTranslation();
    const pleaseNote = apiResponses?.diagnoses_checklist?.please_note;
    const renderablePleaseNote = typeof pleaseNote === 'string' ? pleaseNote : '';
    const [pin,setPin] = useState(() => sessionStorage.getItem('userPin') || null);

    return (
        <ResultsPaper>
            <AnimatedGauge result={getTriageOutcome(triageData?.where_to_now?.triage_score)?.result} />
            <QuestionText>{t("triage_results")}</QuestionText>
            <QuestionText sx={{ color: getTriageOutcome(triageData?.where_to_now?.triage_score)?.color }}>
                {getTriageOutcome(triageData?.where_to_now?.triage_score)?.title}
            </QuestionText>
            <Text>{getTriageOutcome(triageData?.where_to_now?.triage_score)?.text}</Text>
            {renderablePleaseNote && <Text>{renderablePleaseNote}</Text>}
            <ConfirmButton onClick={onNext}>
                {t('see_possible_conditions')}
            </ConfirmButton>
            <CallToAction pin={pin} setPin={setPin} jsonDiag={jsonDiag} />
        </ResultsPaper>
    );
};

export default InitialResultDisplay;
