import { Main, LoaderContainer, Loader, QuestionText } from "./useStyles";
import { CircularProgress } from "@mui/material";
import { useTranslation } from 'react-i18next';

const ResponseAnalyzing = ({ forSelf }) => {
    const { t } = useTranslation();
    const analyzingText = forSelf ? t("analyzing_symptoms") : t("analyzing_symptoms_other");

    return (
        <Main>
            <LoaderContainer>
                <Loader>
                    <CircularProgress color="inherit" size={80} thickness={5.5} />
                </Loader>
                <QuestionText>{analyzingText}</QuestionText>
                <div>
                    <img src={`/${'drita_13.png'}`} alt="logo" style={{ height: '60vh', position: 'relative', bottom: '0px' }} />
                </div>
            </LoaderContainer>
        </Main>
    );
}

export default ResponseAnalyzing;
